const useFavicon = (url) => {
  const link = document.querySelector("link[rel*='icon']");
  if (!url) {
    if (link) {
      link.parentNode.removeChild(link);
    }
  } else {
    const link = document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = url;
    document.head.appendChild(link);
  }
};

export default useFavicon;
